import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import UcariHome from './components/UcariHome.vue';
import TestKits from './components/TestKits.vue';
import RegisterKit from './components/RegisterKit.vue';
import RegisterKitSuccessful from './components/RegisterKitSuccessful.vue';
import TestResults from './components/TestResults.vue';
import AllTestItems from './components/AllTestItems.vue';
import RegisterUser from './components/RegisterUser.vue';
import RegisterUserSuccessful from './components/RegisterUserSuccessful.vue';
import LoginUser from './components/LoginUser.vue';
import VerifyEmail from './components/VerifyEmail.vue';
import RequestPasswordReset from './components/RequestPasswordReset.vue';
import RequestPasswordResetSuccessful from './components/RequestPasswordResetSuccessful.vue';
import ResetPassword from './components/ResetPassword.vue';
import ResetPasswordSuccessful from './components/ResetPasswordSuccessful.vue';
import NotFound from './components/NotFound.vue';

const routes = [
  {
    path: '/welcome',
    name: 'UcariHome',
    component: UcariHome,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'TestKits',
    component: TestKits,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'RegisterUser',
    component: RegisterUser,
    meta: { requiresAuth: false }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: { requiresAuth: false }
  },
  {
    path: '/registration-successful',
    name: 'RegisterUserSuccessful',
    component: RegisterUserSuccessful,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'LoginUser',
    meta: { requiresAuth: false },
    component: LoginUser
  },
  {
    path: '/request-password-reset',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
    meta: { requiresAuth: false }
  },
  {
    path: '/request-password-reset-successful',
    name: 'RequestPasswordResetSuccessful',
    component: RequestPasswordResetSuccessful,
    meta: { requiresAuth: false }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/reset-password-successful',
    name: 'ResetPasswordSuccessful',
    component: ResetPasswordSuccessful,
    meta: { requiresAuth: false }
  },
  {
    path: '/test-results',
    name: 'TestResults',
    component: TestResults,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-test-items',
    name: 'AllTestItems',
    component: AllTestItems,
    meta: { requiresAuth: true }
  },
  {
    path: '/register-kit',
    name: 'RegisterKit',
    component: RegisterKit,
    meta: { requiresAuth: true }
  },
  {
    path: '/kit-registered',
    name: 'RegisterKitSuccessful',
    component: RegisterKitSuccessful,
    meta: { requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
      try {
        await axios.post('/api/logout');
        localStorage.removeItem('accessToken');
        next('/login');
      } catch (error) {
        console.error('Logout failed:', error);
        next(false);
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('accessToken');
    const cid = localStorage.getItem('cid');
    if (token && cid) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/check-auth`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'cid': cid
          }
        });

        if (response.data.authenticated) {
          next();
        } else {
          next({ path: '/login', query: { redirect: to.fullPath } });
        }
      } catch (error) {
        next({ path: '/login', query: { redirect: to.fullPath } });
      }
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
